import './App.css';
import './Queries.css';
import { Routes, Route, Link } from 'react-router-dom';
import { useState } from 'react';

//Import Pages
import Items from './pages/Items.js';
import Categories from './pages/Categories.js';
import Containers from './pages/Containers.js';
import Owners from './pages/Owners.js';

// let passwordGuess = 'null';

function App() {
	const [itemColor, setitemColor] = useState({ color: 'aqua' });
	const [containerColor, setcontainerColor] = useState([]);
	const [categoryColor, setcategoryColor] = useState([]);
	const [ownerColor, setownerColor] = useState([]);

	//Changes color of active navbar link
	function toggleColor(whichLink) {
		const functArr = [
			setitemColor,
			setcontainerColor,
			setcategoryColor,
			setownerColor,
		];
		for (const funct of functArr) funct({ color: 'white' });
		switch (whichLink) {
			case 'container':
				setcontainerColor({ color: 'aqua' });
				break;
			case 'item':
				setitemColor({ color: 'aqua' });
				break;
			case 'category':
				setcategoryColor({ color: 'aqua' });
				break;
			case 'owner':
				setownerColor({ color: 'aqua' });
				break;
			default:
		}
	}

	// if (passwordGuess === 'cat') {
	return (
		<div className="App">
			<nav>
				<h1 id="appTitle">Cole Storage App</h1>
				<div className="navSpacer" id="firstSpacer" />
				<Link to="/">
					<div
						className="navLink"
						id="navItems"
						style={itemColor}
						onClick={() => toggleColor('item')}
					>
						Items
					</div>
				</Link>
				<div className="navSpacer" />
				<Link to="/containers">
					<div
						className="navLink"
						id="navContainers"
						style={containerColor}
						onClick={() => toggleColor('container')}
					>
						Containers
					</div>
				</Link>
				<div className="navSpacer" />
				<Link to="/categories">
					<div
						className="navLink"
						id="navCategories"
						style={categoryColor}
						onClick={() => toggleColor('category')}
					>
						Categories
					</div>
				</Link>
				<div className="navSpacer" />
				<Link to="/owners">
					<div
						className="navLink"
						id="navOwners"
						style={ownerColor}
						onClick={() => toggleColor('owner')}
					>
						Owners
					</div>
				</Link>
			</nav>
			<div id="main">
				<Routes>
					<Route path="/" exact element={<Items />} />
					<Route path="/containers" exact element={<Containers />} />
					<Route path="/categories" exact element={<Categories />} />
					<Route path="/owners" exact element={<Owners />} />
				</Routes>
			</div>
		</div>
	);
	// } else {
	// 	passwordGuess = prompt('Password: ');
	// 	// setTimeout(() => {
	// 	// 	passwordGuess = prompt('Password: ');
	// 	// }, 1000);
	// }
}

export default App;
