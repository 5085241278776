import React, { useState, useEffect } from 'react';

function Categories() {
	const [categories, setCategories] = useState([]);

	async function getData() {
		try {
			const res = await fetch(`/getCategories`, {
				method: 'GET',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/json',
				},
			}); //returns a promise - fetch is not part of express, fetch is part of browser
			let data = await res.json(); //MUST BE THE NAME OF THE SETSTATE VARIABLE ? this isn't true?

			if (res.ok) {
				setCategories(data.categories);
			}
		} catch (error) {
			console.log(error);
		}
	}

	// Deletes a row (backend + frontend)
	async function deleteCategory(categoryID) {
		let res = await fetch('/deleteCategories', {
			method: 'DELETE',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ categoryID: categoryID }),
		});
		if (res.ok) {
			setCategories(categories.filter((row) => row.categoryID !== categoryID));
		}
	}

	async function addCategory() {
		let categoryEl = document.getElementById('inputCategoryName');
		let inputFormEl = document.getElementById('addCategoryForm');

		if (categoryEl.value.trim() === '') {
			return; //prevents blank item name
		}

		const addObj = {
			inputCategoryName: categoryEl.value,
		};
		let res = await fetch('/addCategories', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(addObj),
		});
		if (res.ok) {
			getData();
		}
		// clear the input forms
		inputFormEl.reset();
	}

	useEffect(() => {
		getData();
	}, []);
	return (
		<div className="categoriesMain">
			<div className="tableHeaders">
				<h1>Add Category</h1>
			</div>
			<form id="addCategoryForm">
				<table id="addCategoryTable">
					<thead>
						<tr>
							<th>Category Name</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<input type="text" id="inputCategoryName"></input>
							</td>
							<td>
								<input type="button" onClick={addCategory} value="Add"></input>
							</td>
						</tr>
					</tbody>
				</table>
			</form>
			<br></br>
			<br></br>

			{/* ############################################## */}
			{/* ############################################## */}
			{/* MAIN TABLE */}
			{/* ############################################## */}
			{/* ############################################## */}
			<div className="tableHeaders">
				<h1>Containers</h1>
			</div>
			<table id="containersTable">
				<thead>
					<tr>
						<th>Category Name</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{categories.map((category, i) => {
						return (
							<tr key={category.categoryID} id={category.categoryID}>
								<td>{category.categoryName}</td>
								<td>
									<button onClick={() => deleteCategory(category.categoryID)}>
										Delete
									</button>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}

export default Categories;
