import React, { useState, useEffect } from 'react';

function Owners() {
	const [owners, setOwners] = useState([]);

	async function getData() {
		try {
			const res = await fetch(`/getOwners`, {
				method: 'GET',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/json',
				},
			}); //returns a promise - fetch is not part of express, fetch is part of browser
			let data = await res.json(); //MUST BE THE NAME OF THE SETSTATE VARIABLE ? this isn't true?

			if (res.ok) {
				setOwners(data.owners);
			}
		} catch (error) {
			console.log(error);
		}
	}

	// Deletes a row (backend + frontend)
	async function deleteOwner(ownerID) {
		let res = await fetch('/deleteOwners', {
			method: 'DELETE',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ ownerID: ownerID }),
		});
		if (res.ok) {
			setOwners(owners.filter((row) => row.ownerID !== ownerID));
		}
	}

	async function addOwner() {
		let ownerEl = document.getElementById('inputOwnerName');
		let inputFormEl = document.getElementById('addOwnerForm');

		if (ownerEl.value.trim() === '') {
			return; //prevents blank item name
		}

		const addObj = {
			inputOwnerName: ownerEl.value,
		};
		let res = await fetch('/addOwners', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(addObj),
		});
		if (res.ok) {
			getData();
		}
		// clear the input forms
		inputFormEl.reset();
	}

	useEffect(() => {
		getData();
	}, []);
	return (
		<div className="ownersMain">
			<div className="tableHeaders">
				<h1>Add Owner</h1>
			</div>
			<form id="addOwnerForm">
				<table id="addOwnerTable">
					<thead>
						<tr>
							<th>Owner Name</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<input type="text" id="inputOwnerName"></input>
							</td>
							<td>
								<input type="button" onClick={addOwner} value="Add"></input>
							</td>
						</tr>
					</tbody>
				</table>
			</form>
			<br></br>
			<br></br>

			{/* ############################################## */}
			{/* ############################################## */}
			{/* MAIN TABLE */}
			{/* ############################################## */}
			{/* ############################################## */}
			<div className="tableHeaders">
				<h1>Owners</h1>
			</div>
			<table id="ownersTable">
				<thead>
					<tr>
						<th>Owner Name</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{owners.map((owner, i) => {
						return (
							<tr key={owner.ownerID} id={owner.ownerID}>
								<td>{owner.ownerName}</td>
								<td>
									<button onClick={() => deleteOwner(owner.ownerID)}>
										Delete
									</button>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}

export default Owners;
