import './Login.css';

import React, { useState } from 'react';

//Import Pages
import App from './App.js';

// let passwordGuess = 'null';

function Login() {
	const [isVerified, setIsVerified] = useState(false);
	const [invalidPass, setinvalidPass] = useState(false);

	// whenever a useState variable changes, the page is rerendered
	// use function inside of useState() to only run once
	async function checkPw() {
		// gets the current input value
		const passwordInputEl = document.getElementById('passwordInput');
		const passObj = {
			inputPass: passwordInputEl.value,
		};
		try {
			let res = await fetch('/authenticate', {
				method: 'POST',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(passObj),
			});
			console.log(res.body);
			if (res.status === 200) {
				setIsVerified(true);
			} else {
				setinvalidPass(true);
				setTimeout(() => {
					setinvalidPass(false);
				}, 1000);
				passwordInputEl.value = '';
			}
			console.log(res);
		} catch (err) {
			console.log(err);
		}

		// if (passwordInputEl.value === 'cat') {
		// 	setIsVerified(true);
		// } else {
		// 	//reset
		// 	// TODO: there has to be a better way
		// 	setinvalidPass(true);
		// 	setTimeout(() => {
		// 		setinvalidPass(false);
		// 	}, 1000);
		// 	passwordInputEl.value = '';
		// }
	}

	function togglePass() {
		const passwordEl = document.getElementById('passwordInput');
		const hideshowEl = document.getElementById('hideshow');
		if (passwordEl.type === 'password') {
			passwordEl.type = 'text';
			hideshowEl.innerHTML = '<i>Hide password</i>';
		} else {
			passwordEl.type = 'password';
			hideshowEl.innerHTML = '<i>Show password</i>';
		}
	}

	// useEffect(() => {
	// 	setinvalidPass('loginStatus');
	// }, [invalidPass]);

	// useEffect is always called at least once
	// useEffect is called whenever the values of the second parameter are changed.
	//      to have useEffect fire only once on page load, just include a blank array as second param []
	//      can have multiple useEffect functions
	// return code in useEffect runs first -> ie. it cleans up previous useEffect code:
	//      for example, say we added an event listener in the useEffect function
	//      every time useEffect ran, it would keep adding instances of that event listener
	//      so, we should kill each added event listener before we add a new one, this is where
	//      the return/aka cleanup comes in handy.
	//      example code: return () => { kill event listener}

	return (
		<div>
			{isVerified ? (
				<App />
			) : (
				<div>
					<div id="loginParent">
						<div id="loginHeader">
							<div id="loginTitle">
								<b>Cole Storage App</b>
							</div>
						</div>
						<div id="loginBody">
							<input id="passwordInput" name="password" type="password" />
							<button id="formSubmitButton" type="button" onClick={checkPw}>
								Login
							</button>
							<br></br>
							<button type="button" id="hideshow" onClick={togglePass}>
								<i>Show password</i>
							</button>
						</div>
						<div
							id="loginStatus"
							className={invalidPass ? 'loginStatus showError' : 'loginStatus'}
						>
							<span>Invalid password</span>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default Login;
