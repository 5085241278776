import React, { useState, useEffect } from 'react';

function Containers() {
	const [containers, setContainers] = useState([]);
	const [fullness, setFullness] = useState([]);

	async function getData() {
		const sortBy = document.getElementById('sortByDropdown').value;
		try {
			const res = await fetch(`/getContainers?sortBy=${sortBy}`, {
				method: 'GET',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/json',
				},
			}); //returns a promise - fetch is not part of express, fetch is part of browser
			let data = await res.json(); //MUST BE THE NAME OF THE SETSTATE VARIABLE ? this isn't true?

			if (res.ok) {
				setContainers(data.containers);
				setFullness(data.fullness);
			}
		} catch (error) {
			console.log(error);
		}
	}

	// Is called by the Sort By dropdown menu
	// function initiateSort() {
	// 	let orderByVal = document.getElementById('sortByDropdown').value;
	// 	console.log(orderByVal);
	// 	getData(orderByVal);
	// }

	async function updateContainer(containerID) {
		let fullnessID = document.getElementById(containerID + ':fullness').value;
		const updateObj = {
			containerID: containerID,
			fullnessID: fullnessID,
		};
		console.log(updateObj);
		let res = await fetch('/updateContainers', {
			method: 'PUT',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(updateObj),
		});
		if (res.ok) {
			await getData();
		}
	}

	// Deletes a row (backend + frontend)
	async function deleteContainer(containerID) {
		let res = await fetch('/deleteContainers', {
			method: 'DELETE',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ containerID: containerID }),
		});
		if (res.ok) {
			setContainers(
				containers.filter((row) => row.containerID !== containerID)
			);
		}
	}

	async function addContainer() {
		let containerEl = document.getElementById('inputContainerLabel');
		let fullnessEl = document.getElementById('inputContainerFullness');
		let inputFormEl = document.getElementById('addContainerForm');

		if (containerEl.value.trim() === '') {
			return; //prevents blank item name
		}

		const addObj = {
			inputContainerLabel: containerEl.value,
			inputFullnessID: fullnessEl.value,
		};
		let res = await fetch('/addContainers', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(addObj),
		});
		if (res.ok) {
			getData();
		}
		// clear the input forms
		inputFormEl.reset();
	}

	useEffect(() => {
		getData();
	}, []);

	return (
		<div className="containersMain">
			<div className="tableHeaders">
				<h1>Add Container</h1>
			</div>
			<form id="addContainerForm">
				<table id="addContainerTable">
					<thead>
						<tr>
							<th>Container Label</th>
							<th>Fullness Level</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<input type="text" id="inputContainerLabel"></input>
							</td>
							<td>
								<select id="inputContainerFullness">
									{fullness.map((level, i) => {
										return (
											<option key={level.fullnessID} value={level.fullnessID}>
												{level.fullnessLevel + '%'}
											</option>
										);
									})}
								</select>
							</td>
							<td>
								<input type="button" onClick={addContainer} value="Add"></input>
							</td>
						</tr>
					</tbody>
				</table>
			</form>
			<br></br>
			<br></br>

			{/* ############################################## */}
			{/* ############################################## */}
			{/* MAIN TABLE */}
			{/* ############################################## */}
			{/* ############################################## */}
			<div className="tableHeaders">
				<h1>Containers</h1>
				<div id="sortByDropdownLabel">Sort by:</div>
				<select name="sortByDropdown" id="sortByDropdown" onChange={getData}>
					<option value="Containers.containerLabel">Container Label</option>
					<option value="Fullness.fullnessLevel">Fullness Level</option>
				</select>
			</div>
			<table id="itemsTable">
				<thead>
					<tr>
						<th>Container Label</th>
						<th>Fullness Level</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{containers.map((container, i) => {
						return (
							<tr key={container.containerID} id={container.containerID}>
								<td>{container.containerLabel}</td>
								<td>
									<select
										id={container.containerID + ':fullness'}
										onChange={() => updateContainer(container.containerID)}
										defaultValue={container.fullnessID}
									>
										{fullness.map((level, i) => {
											return (
												<option key={level.fullnessID} value={level.fullnessID}>
													{level.fullnessLevel + '%'}
												</option>
											);
										})}
									</select>
								</td>
								<td>
									<button
										onClick={() => deleteContainer(container.containerID)}
									>
										Delete
									</button>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}

export default Containers;
