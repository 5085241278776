import React, { useState, useEffect } from 'react';

function Items() {
	const [items, setItems] = useState([]);
	const [owners, setOwners] = useState([]);
	const [containers, setContainers] = useState([]);
	const [categories, setCategories] = useState([]);
	let editCellID = null;

	// Is called by the Sort By dropdown menu
	function initiateSearch() {
		let searchByVal = document.getElementById('searchByText').value;
		getData('search', searchByVal);
	}

	// Is called by the Sort By dropdown menu
	function initiateSort() {
		let orderByVal = document.getElementById('sortByDropdown').value;
		getData('order', orderByVal);
	}

	// Gets the data used to populate the website table
	async function getData(searchOrOrder = 'order', filterBy = 'Items.itemName') {
		//Keep the sorting order on page refresh (update, add, etc..)
		if (searchOrOrder === 'order') {
			filterBy = document.getElementById('sortByDropdown').value;
		}
		try {
			const res = await fetch(
				`/getItems?filterBy=${filterBy}&searchOrOrder=${searchOrOrder}`,
				{
					method: 'GET',
					credentials: 'same-origin',
					headers: {
						'Content-Type': 'application/json',
					},
				}
			); //returns a promise - fetch is not part of express, fetch is part of browser
			let data = await res.json(); //MUST BE THE NAME OF THE SETSTATE VARIABLE ? this isn't true?

			if (res.ok) {
				setItems(data.items); //MUST BE THE NAME OF THE SETSTATE FUNCTION VARIABLE
				setOwners(data.owners);
				setContainers(data.containers);
				setCategories(data.categories);
			}
		} catch (error) {
			console.log(error);
		}
	}

	function clearForm() {
		document.getElementById('searchByText').value = '';
		getData('order');
	}

	// Adds a row (backend + frontend)
	async function addItem() {
		let itemNameEl = document.getElementById('inputItemName');
		let itemContainerEl = document.getElementById('inputItemContainer');
		let itemOwnerEl = document.getElementById('inputItemOwner');
		let itemCategoryEl = document.getElementById('inputItemCategory');
		let itemDescriptionEl = document.getElementById('inputItemDescription');
		let inputFormEl = document.getElementById('addItemForm');

		if (itemNameEl.value.trim() === '') {
			return; //prevents blank item name
		}

		const addObj = {
			inputItemName: itemNameEl.value,
			inputItemDescription: itemDescriptionEl.value,
			inputItemOwner: itemOwnerEl.value,
			inputItemContainer: itemContainerEl.value,
			inputItemCategory: itemCategoryEl.value,
		};
		let res = await fetch('/addItems', {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(addObj),
		});
		if (res.ok) {
			getData();
		}
		// clear the input forms
		inputFormEl.reset();
	}

	// Deletes a row (backend + frontend)
	async function deleteItem(itemID) {
		let res = await fetch('/deleteItems', {
			method: 'DELETE',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ itemID: itemID }),
		});
		if (res.ok) {
			setItems(items.filter((row) => row.itemID !== itemID));
		}
	}

	// Updates a row (backend + frontend)
	async function updateItem(itemID, columnName, columnValue) {
		const updateObj = {
			itemID: itemID,
			columnName: columnName,
			columnValue: columnValue,
		};

		let res = await fetch('/updateItems', {
			method: 'PUT',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(updateObj),
		});
		if (res.ok) {
			await getData();
		}
	}

	// Closes an edit form within a provided cell
	function closeEditForm(curCell) {
		// figure out what type of form it is (dropdown or textInput)
		if (!editCellID) {
			return; //prevents error
		}
		const columnIDArr = editCellID.split(':');
		const columnName = columnIDArr[1];

		const htmlElementValue = curCell.firstChild.value;
		if (columnName === 'itemDescription' || columnName === 'itemName') {
			curCell.innerHTML = htmlElementValue;
		}

		editCellID = null;
	}

	// Opens up an edit form for the cell once it is double clicked on by the user
	function openEditForm(event) {
		// close open form editors
		if (editCellID) {
			const prevCell = document.getElementById(editCellID);
			closeEditForm(prevCell);
		}

		// open form editor
		editCellID = event.target.id;
		if (editCellID === '') return; // handles a child dblclick

		// Get itemID and column name
		const curCell = document.getElementById(editCellID);
		const columnIDArr = editCellID.split(':');
		const columnName = columnIDArr[1];
		const itemID = parseInt(columnIDArr[0]);

		// Text entry
		if (columnName === 'itemDescription' || columnName === 'itemName') {
			const cellContents = curCell.innerHTML;
			curCell.innerHTML = `<input type="text" value="${cellContents}" size=${cellContents.length} />`;
			curCell.firstChild.focus();
			curCell.firstChild.select();

			// Test enter key detection
			const input = document.getElementById(editCellID);
			input.addEventListener('keyup', (event) => {
				if (event.key === 'Enter') {
					closeEditForm(curCell);
					const formText = curCell.innerHTML;
					if (columnName === 'itemName' && formText.trim() === '') {
						return; //prevents user from inputing a blank name
					}
					if (cellContents !== formText) {
						updateItem(itemID, columnName, formText);
					}
				}
				if (event.key === 'Escape') {
					curCell.innerHTML = cellContents;
					editCellID = null;
					// closeEditForm(curCell);
					// const formText = curCell.innerHTML;
					// if (columnName === 'itemName' && formText.trim() === '') {
					// 	return; //prevents user from inputing a blank name
					// }
					// if (cellContents !== formText) {
					// 	updateItem(itemID, columnName, formText);
					// }
				}
			});
		} else {
			// Dropdown entry
			const dropdownValue = parseInt(curCell.value);
			updateItem(itemID, columnName, dropdownValue);
		}
	}

	useEffect(() => {
		getData();
	}, []);

	return (
		<div className="itemsMain">
			<div className="tableHeaders">
				<h1>Add Item</h1>
			</div>
			<form id="addItemForm">
				<table id="addItemTable">
					<thead>
						<tr>
							<th>Item Name</th>
							<th>Container</th>
							<th className="ownerColumn">Owner</th>
							<th className="categoryColumn">Category</th>
							<th className="descriptionColumn">Description</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<input type="text" id="inputItemName"></input>
							</td>
							<td>
								<select id="inputItemContainer">
									{containers.map((container, i) => {
										return (
											<option
												key={container.containerID}
												value={container.containerID}
											>
												{container.containerLabel}
											</option>
										);
									})}
								</select>
							</td>
							<td className="ownerColumn">
								<select id="inputItemOwner">
									<option value="NULL">None</option>
									{owners.map((owner, i) => {
										return (
											<option key={owner.ownerID} value={owner.ownerID}>
												{owner.ownerName}
											</option>
										);
									})}
								</select>
							</td>
							<td className="categoryColumn">
								<select id="inputItemCategory">
									<option value="NULL">None</option>
									{categories.map((category, i) => {
										return (
											<option
												key={category.categoryID}
												value={category.categoryID}
											>
												{category.categoryName}
											</option>
										);
									})}
								</select>
							</td>
							<td className="descriptionColumn">
								<input type="text" id="inputItemDescription"></input>
							</td>
							<td>
								<input type="button" onClick={addItem} value="Add"></input>
							</td>
						</tr>
					</tbody>
				</table>
			</form>
			<br></br>
			<br></br>

			{/* ############################################## */}
			{/* ############################################## */}
			{/* MAIN TABLE */}
			{/* ############################################## */}
			{/* ############################################## */}
			<div className="tableHeaders">
				<h1>Items</h1>
				<div id="sortByDropdownLabel">Sort by:</div>
				<select
					name="sortByDropdown"
					id="sortByDropdown"
					onChange={initiateSort}
				>
					<option value="Items.itemName">Item Name</option>
					<option value="Containers.containerLabel">Container Label</option>
					<option value="Owners.ownerName">Owner Name</option>
					<option value="Categories.categoryName">Category Name</option>
				</select>
				{/* linebreaker div used for media queries. Hidden by default */}
				<div id="lineBreaker">
					<br></br>
				</div>
				{/* Searchmasterdiv used for media queries */}
				<div id="searchByLabel">Search by Item Name:</div>
				<div id="searchByLabelMediaQuery">Search name:</div>
				<input type="text" id="searchByText"></input>
				<div id="lineBreaker2">
					<br></br>
				</div>
				<input
					type="button"
					id="searchByTextButton"
					value="Search"
					onClick={initiateSearch}
				/>
				<input
					type="button"
					id="clearResultsButton"
					value="Clear"
					onClick={clearForm}
				/>
			</div>
			<table id="itemsTable">
				<thead>
					<tr>
						<th>Item Name</th>
						<th>Container</th>
						<th className="ownerColumn">Owner</th>
						<th className="categoryColumn">Category</th>
						<th className="descriptionColumn">Description</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{items.map((item, i) => {
						return (
							<tr key={item.itemID} id={item.itemID}>
								<td
									id={item.itemID + ':itemName'}
									className="itemName"
									onDoubleClick={openEditForm}
								>
									{item.itemName}
								</td>
								<td className="containerLabel">
									<select
										id={item.itemID + ':containerID'}
										onChange={openEditForm}
										defaultValue={item.containerID}
									>
										{containers.map((container, i) => {
											return (
												<option
													key={container.containerID}
													value={container.containerID}
												>
													{container.containerLabel}
												</option>
											);
										})}
									</select>
								</td>
								<td className="ownerName ownerColumn">
									<select
										id={item.itemID + ':ownerID'}
										onChange={openEditForm}
										// defaultValue={item.ownerID}
										defaultValue={(() => {
											if (item.ownerID) {
												return item.ownerID;
											} else {
												return 'NULL';
											}
										})()}
									>
										{owners.map((owner, i) => {
											return (
												<option key={owner.ownerID} value={owner.ownerID}>
													{owner.ownerName}
												</option>
											);
										})}
										<option value="NULL">None</option>
									</select>
								</td>
								<td className="categoryName categoryColumn">
									<select
										id={item.itemID + ':categoryID'}
										onChange={openEditForm}
										defaultValue={(() => {
											if (item.categoryID) {
												return item.categoryID;
											} else {
												return 'NULL';
											}
										})()}
									>
										{categories.map((category, i) => {
											return (
												<option
													key={category.categoryID}
													value={category.categoryID}
												>
													{category.categoryName}
												</option>
											);
										})}
										<option value="NULL">None</option>
									</select>
								</td>
								<td
									id={item.itemID + ':itemDescription'}
									className="itemDescription descriptionColumn"
									onDoubleClick={openEditForm}
								>
									{item.itemDescription}
								</td>
								<td>
									<button onClick={() => deleteItem(item.itemID)}>
										Delete
									</button>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}

export default Items;
